<template>
    <div class="container">

 <v-btn  @click="$router.go(-1)"  > Back  </v-btn>
<div class="row">


    <div class="col-md-5">


 <v-card
    max-width="375"
    class="mx-auto pl-6"
  >

    <v-card-title class="text-align-center"> Company : {{contactform.company}}  </v-card-title>

    <v-list class="align-end" two-line>
      <v-list-item>
        <v-list-item-action>
          Contact
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title >{{contactform.firstName}} {{contactform.lastName}}</v-list-item-title>
        </v-list-item-content>



   
      </v-list-item>

        <v-list-item>
        <v-list-item-action>
          Status
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title >{{contactform.status}}</v-list-item-title>
        </v-list-item-content>


   
      </v-list-item>
  <v-list-item>
        <v-list-item-action>
          contactform Date
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title >{{contactform.created_at | date_format}}</v-list-item-title>
        </v-list-item-content>



   
      </v-list-item>



     <v-list-item>
        <v-list-item-action>
          Contact
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title >{{contactform.email}}</v-list-item-title>
        </v-list-item-content>



   
      </v-list-item>


   



    

      <v-divider inset></v-divider>



      <v-list-item>
<v-list-item-content>

 <div class="display-2">  Message:  </div> <br>
{{contactform.message}}
          
</v-list-item-content>

        
      </v-list-item>

    </v-list>
  </v-card>





    </div>

    <div class="col-md-7">




    </div>

</div>









    </div>
</template>



<script>
var moment = require("moment");
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import gql from "graphql-tag";

import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";


export default {

      mixins: [validationMixin],

  data() {
    return {
      contactform: {},
      moment: moment,
      company: null,
      search: "",
      companies: [],

         typeItems: [
        {
          text: 'Buyer',
          value: 'seller'
        }
      ],
    
      type: "seller",
      email: "",
      company_name: "",
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id
    };
  },



    filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    fullName(user) {
      return (user.FirstName || "") + " " + (user.LastName || "");
    }
  },




  apollo: {
    contactform: {
      query: gql`
        query Contactforms($id: ID!) {
          contactform(id: $id) {
            
            id
        status
    company
      firstName
        created_at
      lastName
      
      email
      message
      
      
      
        
          }
        }
      `,
      variables() {
        return {
          id: this.routeParam
        };
      }
    },

  }
};
</script>

